define("alpha-components/components/alpha-pagination", ["exports", "alpha-components/templates/components/alpha-pagination"], function (_exports, _alphaPagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _alphaPagination.default,
    meta: null,
    page: null,
    size: Ember.computed.alias('meta.pageSize'),
    totalPages: Ember.computed.alias('meta.totalPages'),
    pages: Ember.computed('totalPages', 'page', function () {
      var result = [];

      for (var i = 1; i <= this.get('totalPages'); i++) {
        if (i >= this.get('page') - 3 && i <= this.get('page') + 3) {
          result.push(i);
        }
      }

      return result;
    }),
    showFirst: Ember.computed('page', function () {
      if (this.get('page') > 5) {
        this.set('showFirstDivider', true);
      } else {
        this.set('showFirstDivider', false);
      }

      return this.get('page') >= 5;
    }),
    showLast: Ember.computed('page', function () {
      if (this.get('totalPages') > this.get('page') + 4) {
        this.set('showLastDivider', true);
      } else {
        this.set('showLastDivider', false);
      }

      return this.get('totalPages') - 4 >= this.get('page');
    }),
    onPageChanged: function onPageChanged() {},
    actions: {
      change: function change(page) {
        var action = Ember.get(this, 'onPageChanged');
        action(page);
        this.set('page', page);
      }
    }
  });

  _exports.default = _default;
});