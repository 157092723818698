define("alpha-data/models/role", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    userUnit: (0, _relationships.hasMany)('user-unit-role', {
      async: false
    }),
    name: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    desciption: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});