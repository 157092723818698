define("alpha-data/adapters/web-api", ["exports", "ember-data", "@ember-data/adapter/error"], function (_exports, _emberData, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    isInvalid: function isInvalid(
    /*, headers, payload*/
    status) {
      return status === 422 || status === 400;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      } else if (this.isInvalid(status, headers, payload)) {
        var error = new _error.InvalidError(this.errorsHashToArray(payload.validations));
        error.status = status;
        error.message = payload.message;
        return error;
      }

      var errors = this.normalizeErrorResponse(status, headers, payload);
      var result = new _error.default(errors);
      result.status = status;
      result.message = payload.message;
      return result;
    },

    /**
      @method errorsHashToArray
      @private
    */
    errorsHashToArray: function errorsHashToArray(errors) {
      var out = [];

      if (Ember.isPresent(errors)) {
        Object.keys(errors).forEach(function (key) {
          var messages = errors[key];

          for (var i = 0; i < messages.length; i++) {
            out.push({
              title: 'Invalid Attribute',
              detail: messages[i],
              source: {
                pointer: "/data/attributes/".concat(key)
              }
            });
          }
        });
      }

      return out;
    }
  });

  _exports.default = _default;
});