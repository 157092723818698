define("alpha-data/models/contact", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    person: (0, _relationships.belongsTo)('person', {
      async: false
    }),
    company: (0, _relationships.belongsTo)('company', {
      async: false
    }),
    postalAddress: (0, _relationships.belongsTo)('postal-address', {
      async: false
    }),
    contactId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    personId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    companyId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    postalAddressId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    customerNumber: (0, _attr.default)('number', {
      defaultValue: null
    }),
    kindOfContact: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    isInactive: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    inactiveReason: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    inactiveSince: (0, _attr.default)('date', {
      defaultValue: null
    }),
    inactiveUntil: (0, _attr.default)('date', {
      defaultValue: null
    }),
    role: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    role2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    department: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    department2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    extraAddressLine: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    addressFormat: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    phone: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    fax: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    eMailAddress: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    mobilePhone: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    homepage: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    miscContact: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    miscContact2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    formOfAddress: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    label1: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    label2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    eventBillingContactId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    eventBillingContact: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    eventBillingInformation: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    eventTermOfPayment: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    tag1: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    tag2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    unitKey: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastUpdate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    lastUser: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});