define("alpha-data/models/item-tag", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    module: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    itemId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    tag: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    category: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    unitKey: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});