define("ember-tui-editor/templates/components/tui-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "41/g8Kju",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[13,1],[3,\"did-insert\",[[23,0,[\"setupEditor\"]]]],[3,\"will-destroy\",[[23,0,[\"destroyEditor\"]]]],[8],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-tui-editor/templates/components/tui-editor.hbs"
    }
  });

  _exports.default = _default;
});