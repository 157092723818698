define("alpha-data/mixins/ressource-list-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service Mixin
   *
   * Extends a Service to retreive full list of defined type from store
   *
   * @example
   * import Ember from 'ember';
   * import RessourceListMixin from 'alpha-data/mixins/services/ressource-list-mixin';
   *
   * export default Ember.Service.extend(RessourceListMixin, {
   *   modelName: 'category'
   * });
   *
   * @uses Ember.PromiseProxyMixin
   * @public
   */
  var _default = Ember.Mixin.create(Ember.PromiseProxyMixin, {
    store: Ember.inject.service(),
    result: null,
    pageSize: 500,
    query: function query(page, pageSize) {
      return this.store.query(this.modelName, {
        page: page,
        pageSize: pageSize
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.reload();
    },
    reload: function reload() {
      var _this = this;

      var self = this; // TODO: Seit 2.3.0-beta3 fehler in tests

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.set('promise', new Ember.RSVP.Promise(function (resolve, reject) {
        var pageSize = self.get('pageSize');

        _this.query(1, pageSize).then(function (results) {
          var totalPagesRemaining = results.get('meta.totalPages');
          var n = 1;
          var promises = [];

          while (n < totalPagesRemaining) {
            n += 1;
            promises.pushObject(_this.query(n, pageSize));
          }

          Ember.RSVP.all(promises).then(function () {
            var result = _this.store.peekAll(_this.modelName);

            self.set('result', result);
            resolve(result);
          }).catch(function (err) {
            reject(err);
          });
        }).catch(function (err) {
          reject(err);
        });
      }));
    }
  });

  _exports.default = _default;
});