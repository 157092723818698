define("alpha-data/services/units-list", ["exports", "alpha-data/mixins/ressource-list-mixin"], function (_exports, _ressourceListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_ressourceListMixin.default, {
    modelName: 'unit'
  });

  _exports.default = _default;
});