define("ember-light-table/templates/components/light-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BUxeI90H",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"head\",\"body\",\"foot\"],[[28,\"component\",[\"lt-head\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[24,[\"elementId\"]],[24,[\"table\"]],[24,[\"tableActions\"]],[24,[\"extra\"]],[24,[\"tableClassNames\"]],[24,[\"sharedOptions\"]]]]],[28,\"component\",[\"lt-body\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[24,[\"elementId\"]],[24,[\"table\"]],[24,[\"tableActions\"]],[24,[\"extra\"]],[24,[\"tableClassNames\"]],[24,[\"sharedOptions\"]]]]],[28,\"component\",[\"lt-foot\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[24,[\"elementId\"]],[24,[\"table\"]],[24,[\"tableActions\"]],[24,[\"extra\"]],[24,[\"tableClassNames\"]],[24,[\"sharedOptions\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/light-table.hbs"
    }
  });

  _exports.default = _default;
});