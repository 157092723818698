define("ember-light-table/templates/components/lt-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIGlXS5S",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"concat\",[\"light-table/cells/\",[23,1,[\"cellType\"]]],null],[23,1,[]],[24,[\"row\"]]],[[\"table\",\"rawValue\",\"tableActions\",\"extra\"],[[24,[\"table\"]],[28,\"get\",[[24,[\"row\"]],[23,1,[\"valuePath\"]]],null],[24,[\"tableActions\"]],[24,[\"extra\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-row.hbs"
    }
  });

  _exports.default = _default;
});