define("alpha-data/models/change-log", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    changeLogId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    timeOfChange: (0, _attr.default)('date', {
      defaultValue: null
    }),
    module: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    itemId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    changedTable: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    changedItemId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    rowDescription: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    kindOfChange: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    changedRange: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    changedInfo: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    user: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    oldValue: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    newValue: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    formatCulture: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    isDetail: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    isSystemLog: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    orderInTime: (0, _attr.default)('number', {
      defaultValue: null
    })
  });

  _exports.default = _default;
});