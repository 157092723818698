define("alpha-data/serializers/base", ["exports", "ember-data/serializers/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _rest.default.extend({
    keyForRelationship: function keyForRelationship(key, typeClass, method) {
      if (typeClass === 'belongsTo' && method === 'deserialize') {
        return "".concat(key, "Id");
      }

      if (typeClass === 'belongsTo' && method === 'serialize') {
        return "".concat(key, "Id");
      }
    },

    /**
    @method serializeIntoHash
    @param {Object} hash
    @param {DS.Model} typeClass
    @param {DS.Snapshot} snapshot
    @param {Object} options
    @public
    */
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      Ember.assign(hash, this.serialize(snapshot, options));
    },

    /**
      Nur geänderte attribute sollen serialisiert werden
      @method serialize
      @param {DS.Snapshot} snapshot
      @param {Object} options
      @return {Object} json
      @public
    */
    serialize: function serialize(
    /*, options*/
    snapshot) {
      var _this = this;

      var self = this;
      var json = {};

      if (snapshot.id) {
        json[this.primaryKey] = snapshot.id;
      }

      snapshot.eachAttribute(function (key, attribute) {
        if (attribute.type === 'array') {
          self.serializeAttribute(snapshot, json, key, attribute);
        }
      });
      var changedAttributes = snapshot.changedAttributes();
      Object.keys(changedAttributes).forEach(function (key) {
        var _changedAttributes$ke = _slicedToArray(changedAttributes[key], 2),
            newValue = _changedAttributes$ke[1];

        _this.serializeAttribute(snapshot, json, key, newValue);
      });
      snapshot.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'belongsTo') {
          _this.serializeBelongsTo(snapshot, json, relationship);
        } else if (relationship.kind === 'hasMany') {
          _this.serializeHasMany(snapshot, json, relationship);
        }
      });
      return json;
    }
  });

  _exports.default = _default;
});