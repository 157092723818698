define('ember-custom-actions/serializers/rest', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var RESTSerializer = _emberData.default.RESTSerializer;
  var makeArray = Ember.makeArray,
      warn = Ember.warn;
  exports.default = RESTSerializer.extend({
    pushPayload: function pushPayload(store, payload) {
      var _this = this;

      var documentHash = {
        data: [],
        included: []
      };

      var _loop = function _loop(prop) {
        var modelName = _this.modelNameFromPayloadKey(prop);
        if (!store.modelFactoryFor(modelName)) {
          warn(_this.warnMessageNoModelForKey(prop, modelName), false, {
            id: 'ds.serializer.model-for-key-missing'
          });
          return 'continue';
        }
        var type = store.modelFor(modelName);
        var typeSerializer = store.serializerFor(type.modelName);

        makeArray(payload[prop]).forEach(function (hash) {
          var _typeSerializer$norma = typeSerializer.normalize(type, hash, prop),
              data = _typeSerializer$norma.data,
              included = _typeSerializer$norma.included;

          documentHash.data.push(data);
          if (included) {
            var _documentHash$include;

            (_documentHash$include = documentHash.included).push.apply(_documentHash$include, _toConsumableArray(included));
          }
        });
      };

      for (var prop in payload) {
        var _ret = _loop(prop);

        if (_ret === 'continue') continue;
      }

      return store.push(documentHash);
    }
  });
});