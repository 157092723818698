define("alpha-data/models/correspondence", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    correspondenceId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    module: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    itemId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    date: (0, _attr.default)('date', {
      defaultValue: null
    }),
    title: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    correspondenceType: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    tag: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    rating: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    notes: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    todo: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    deadline: (0, _attr.default)('date', {
      defaultValue: null
    }),
    responsibleUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    isDone: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    feedback: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    completionTime: (0, _attr.default)('date', {
      defaultValue: null
    }),
    todoInitiator: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    fileName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    linkedModule: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    linkedItemId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    blobId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    mailId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    moduleItemDescription: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    processId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    accessibility: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    accessibilityToken: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    isSystemEntry: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    subregion: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});