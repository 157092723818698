define("alpha-data/models/contact-category", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    contactCategoryId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    contactId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    categoryId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    validFrom: (0, _attr.default)('date', {
      defaultValue: null
    }),
    validTo: (0, _attr.default)('date', {
      defaultValue: null
    }),
    isInactive: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    role: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    listText: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    quantity: (0, _attr.default)('number', {
      defaultValue: null
    }),
    notes: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});