define('ember-intl/models/translation', ['exports', 'ember-intl/utils/empty-object'], function (exports, _emptyObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TranslationModel = Ember.Object.extend({
    localeName: null,

    init: function init() {
      this._super();

      if (!this.translations) {
        this.translations = new _emptyObject.default();
      }
    },
    addTranslation: function addTranslation(key, value) {
      Ember.set(this.translations, key, value);
    },
    addTranslations: function addTranslations(translationsObject) {
      for (var key in translationsObject) {
        this.addTranslation(key, translationsObject[key]);
      }
    },
    getValue: function getValue(key) {
      var translation = Ember.get(this.translations, key);

      if (typeof translation === 'string') {
        return translation;
      }

      translation = Ember.get(this, key);

      if (typeof translation === 'string') {
        Ember.deprecate('[ember-intl] translations should be added via the `addTranslations`/`addTranslation` API.', false, {
          id: 'ember-intl-add-translation',
          until: '3.0.0'
        });

        return translation;
      }
    },
    has: function has(key) {
      return typeof this.getValue(key) === 'string';
    }
  });

  exports.default = TranslationModel;
});