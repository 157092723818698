define("ember-custom-actions/utils/normalize-payload", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (payload, operation) {
    if (operation) {
      assert("This normalize method of custom action's payload does not exist. Check Ember.String documentation!", !!String[operation]);
      return transformObject(payload, operation);
    } else {
      return payload;
    }
  };

  var assert = Ember.assert,
      String = Ember.String,
      isArray = Ember.isArray;


  function transformObject(object, operation) {
    if (object instanceof Object && !isArray(object)) {
      var data = {};

      Object.keys(object).forEach(function (key) {
        data[String[operation](key)] = transformObject(object[key], operation);
      });

      return data;
    } else {
      return object;
    }
  }
});