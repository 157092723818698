define('ember-custom-actions/utils/url-builder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert,
      computed = Ember.computed,
      EmberObject = Ember.Object,
      $ = Ember.$;
  exports.default = EmberObject.extend({
    params: {},

    modelName: computed('model', function () {
      var _get = this.get('model'),
          constructor = _get.constructor;

      return constructor.modelName || constructor.typeKey;
    }),

    snapshot: computed('model', function () {
      return this.get('model')._createSnapshot();
    }),

    build: function build() {
      assert('You must provide a path for model action!', this.get('path'));
      assert('Model has to be persisted!', !(this.get('instance') && !this.get('model.id')));

      var id = this.get('instance') ? this.get('model.id') : null;
      return this._makeUrl(this._buildUrl(id));
    },
    _buildUrl: function _buildUrl(id) {
      var query = this.get('params');
      var snapshot = this.get('snapshot');
      var modelName = this.get('modelName');
      var requestType = this.get('urlType');

      return this.get('adapter').buildURL(modelName, id, snapshot, requestType, query);
    },
    _makeUrl: function _makeUrl(url) {
      var pathUrl = '';
      var query = $.param(this.get('params'));

      if (url.charAt(url.length - 1) === '/') {
        pathUrl = '' + url + this.get('path');
      } else {
        pathUrl = url + '/' + this.get('path');
      }

      if (query) {
        return pathUrl + '?' + query;
      } else {
        return pathUrl;
      }
    }
  });
});