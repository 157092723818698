define("alpha-data/models/unit", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    unitId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    key: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    name: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    shortName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    customerName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    street: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    zipCode: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    city: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    bank: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    iBAN: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    bIC: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    phone: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    fax: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    eMailAddress: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    homepage: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    districtCourt: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    taxNumber: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    cEO: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    parentId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    leftExtent: (0, _attr.default)('number', {
      defaultValue: null
    }),
    rightExtent: (0, _attr.default)('number', {
      defaultValue: null
    })
  });

  _exports.default = _default;
});