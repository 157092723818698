define("ember-light-table/templates/components/lt-spanned-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VSeHOwGY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[11,\"class\",[29,[\"lt-row \",[28,\"html-safe\",[[24,[\"classes\"]]],null]]]],[8],[0,\"\\n    \"],[7,\"td\",true],[11,\"colspan\",[22,\"colspan\"]],[8],[0,\"\\n      \"],[14,1,[[24,[\"yield\"]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-spanned-row.hbs"
    }
  });

  _exports.default = _default;
});