define("alpha-data/models/person", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    salutation: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    salutationAffix: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    title: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    firstName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    firstNamePhonetic: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    nobility: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastNamePhonetic: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    nameAffix: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    dateOfBirth: (0, _attr.default)('date', {
      defaultValue: null
    }),
    placeOfBirth: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    profession: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    restrictionNote: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    eventNotes: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    notes: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastUpdate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    sourceName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    sourceId: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});