define("alpha-data/models/process-log", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    entryId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    module: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    key: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    beginning: (0, _attr.default)('date', {
      defaultValue: null
    }),
    end: (0, _attr.default)('date', {
      defaultValue: null
    }),
    summary: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    resultReference: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    resultId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});