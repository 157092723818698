define("ember-leaflet/templates/leaflet-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1MEb5WTi",
    "block": "{\"symbols\":[\"components\",\"c\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"tile\",\"wms-tile\",\"marker\",\"circle\",\"circle-marker\",\"image\",\"video\",\"polyline\",\"polygon\",\"geojson\",\"wmsTile\",\"geoJSON\"],[[28,\"component\",[\"tile-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"wms-tile-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"marker-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"circle-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"circle-marker-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"image-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"video-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"polyline-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"polygon-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"geojson-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"wms-tile-layer\"],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"component\",[\"geojson-layer\"],[[\"parentComponent\"],[[23,0,[]]]]]]]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"emberLeaflet\",\"components\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"emberLeaflet\",\"components\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ember-leaflet-assign-to\",[[23,1,[]]],[[\"key\",\"value\",\"onChange\"],[[23,2,[\"as\"]],[28,\"component\",[[23,2,[\"name\"]]],[[\"parentComponent\"],[[23,0,[]]]]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"mergedComponents\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n    \"],[14,3,[[24,[\"mergedComponents\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,3,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-leaflet/templates/leaflet-map.hbs"
    }
  });

  _exports.default = _default;
});