define("ember-scrollable/templates/components/ember-scrollbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cMIwR5tU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"drag-handle \",[28,\"if\",[[24,[\"showHandle\"]],\"visible\"],null]]]],[11,\"onmousedown\",[28,\"action\",[[23,0,[]],\"startDrag\"],null]],[11,\"style\",[22,\"handleStyles\"]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-scrollable/templates/components/ember-scrollbar.hbs"
    }
  });

  _exports.default = _default;
});