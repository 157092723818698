define("ember-light-table/templates/components/lt-foot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T913S2t/",
    "block": "{\"symbols\":[\"column\",\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[28,\"concat\",[[24,[\"tableId\"]],\"_inline_foot\"],null],[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[7,\"table\",true],[11,\"class\",[22,\"tableClassNames\"]],[8],[0,\"\\n    \"],[7,\"tfoot\",true],[10,\"class\",\"lt-foot\"],[8],[0,\"\\n\"],[0,\"      \"],[1,[28,\"lt-scaffolding-row\",null,[[\"columns\"],[[24,[\"columns\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"        \"],[14,2,[[24,[\"columns\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"component\",[[28,\"concat\",[\"light-table/columns/\",[23,1,[\"type\"]]],null],[23,1,[]]],[[\"table\",\"tableActions\",\"extra\",\"sortIcons\",\"resizeOnDrag\",\"click\",\"doubleClick\",\"onColumnResized\",\"onColumnDrag\",\"onColumnDrop\"],[[24,[\"table\"]],[24,[\"tableActions\"]],[24,[\"extra\"]],[24,[\"sortIcons\"]],[24,[\"resizeOnDrag\"]],[28,\"action\",[[23,0,[]],\"onColumnClick\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"onColumnDoubleClick\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"onColumnResized\"],null],[28,\"action\",[[23,0,[]],\"onColumnDrag\"],null],[28,\"action\",[[23,0,[]],\"onColumnDrop\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-foot.hbs"
    }
  });

  _exports.default = _default;
});