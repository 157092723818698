define("alpha-data/models/event-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    root: (0, _relationships.belongsTo)('event-item', {
      inverse: null
    }),
    parent: (0, _relationships.belongsTo)('event-item', {
      inverse: 'children'
    }),
    children: (0, _relationships.hasMany)('event-item', {
      inverse: 'parent'
    }),
    eventParticipants: (0, _relationships.hasMany)('event-participant', {
      inverse: 'eventRootItem'
    }),
    eventItemPrices: (0, _relationships.hasMany)('event-item-price'),
    eventItemId: (0, _attr.default)('string'),
    level: (0, _attr.default)('number'),
    kind: (0, _attr.default)('string'),
    rootId: (0, _attr.default)('string'),
    key: (0, _attr.default)('string'),
    parentId: (0, _attr.default)('string'),
    subkeyCounter: (0, _attr.default)('number'),
    serialNumber: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    beginning: (0, _attr.default)('date'),
    end: (0, _attr.default)('date'),
    timeSpecification: (0, _attr.default)('string'),
    teachingUnits: (0, _attr.default)('number'),
    mandator: (0, _attr.default)('string'),
    eventType: (0, _attr.default)('string'),
    subjectArea: (0, _attr.default)('string'),
    minimum: (0, _attr.default)('number'),
    maximum: (0, _attr.default)('number'),
    closingDate: (0, _attr.default)('date'),
    choice: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('string'),
    location: (0, _attr.default)('string'),
    cancellationDeadline: (0, _attr.default)('date'),
    cancellationObserved: (0, _attr.default)('boolean'),
    cancellationObservedBy: (0, _attr.default)('string'),
    roomId: (0, _attr.default)('string'),
    room: (0, _attr.default)('string'),
    director: (0, _attr.default)('string'),
    directorUserName: (0, _attr.default)('string'),
    administrator: (0, _attr.default)('string'),
    administratorUserName: (0, _attr.default)('string'),
    taxIsIncluded: (0, _attr.default)('boolean'),
    taxRate: (0, _attr.default)('number'),
    defaultPrice: (0, _attr.default)('number'),
    currency: (0, _attr.default)('string'),
    priceIsIncludedAbove: (0, _attr.default)('boolean'),
    differingReceiptCaption: (0, _attr.default)('string'),
    receiptDescription: (0, _attr.default)('string'),
    isExpense: (0, _attr.default)('boolean'),
    scheduledBillingDate: (0, _attr.default)('date'),
    account: (0, _attr.default)('string'),
    costObject: (0, _attr.default)('string'),
    costUnit: (0, _attr.default)('string'),
    excludeOnCancellationCharge: (0, _attr.default)('boolean'),
    cancellationTaxRate: (0, _attr.default)('number'),
    cancellationAccount: (0, _attr.default)('string'),
    cancellationCostObject: (0, _attr.default)('string'),
    cancellationCostUnit: (0, _attr.default)('string'),
    registrationReportText: (0, _attr.default)('string'),
    receiptIntroducingWords: (0, _attr.default)('string'),
    receiptIntroducingWords2: (0, _attr.default)('string'),
    receiptClosingWords: (0, _attr.default)('string'),
    receiptClosingWords2: (0, _attr.default)('string'),
    participantText1Caption: (0, _attr.default)('string'),
    participantText2Caption: (0, _attr.default)('string'),
    participantText3Caption: (0, _attr.default)('string'),
    participantDate1Caption: (0, _attr.default)('string'),
    participantDate2Caption: (0, _attr.default)('string'),
    participantInteger1Caption: (0, _attr.default)('string'),
    participantInteger2Caption: (0, _attr.default)('string'),
    participantNumeric1Caption: (0, _attr.default)('string'),
    participantBool1Caption: (0, _attr.default)('string'),
    participantBool2Caption: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string'),
    participantCounter: (0, _attr.default)('number'),
    created: (0, _attr.default)('date'),
    createUser: (0, _attr.default)('string'),
    lastUpdate: (0, _attr.default)('date'),
    lastUser: (0, _attr.default)('string'),
    unitKey: (0, _attr.default)('string'),
    isCancelled: (0, _attr.default)('boolean'),
    cancellationDate: (0, _attr.default)('date'),
    statisticsGroup: (0, _attr.default)('string'),
    productGroup: (0, _attr.default)('string')
  });

  _exports.default = _default;
});