define("alpha-data/models/notification", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    notificationId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    userName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    class: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    messageText: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    module: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    moduleItemId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    viewed: (0, _attr.default)('date', {
      defaultValue: null
    }),
    tag: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    sourceName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    sourceId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});