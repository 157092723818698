define("alpha-data/models/user-unit-role", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import attr from 'ember-data/attr';
  var _default = _model.default.extend({
    userUnit: (0, _relationships.belongsTo)('user-unit', {
      async: false
    }),
    role: (0, _relationships.belongsTo)('role', {
      async: false
    })
  });

  _exports.default = _default;
});