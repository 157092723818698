define("alpha-data/models/service", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    serviceId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    mandator: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    kindOfService: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    number: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    subscriberId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    form: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    formCaption: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    state: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    stateCaption: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    beginning: (0, _attr.default)('date', {
      defaultValue: null
    }),
    end: (0, _attr.default)('date', {
      defaultValue: null
    }),
    source: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    dateOfOrder: (0, _attr.default)('date', {
      defaultValue: null
    }),
    customerReferenceNumber: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    quittingDate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    quittingReason: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    termOfPayment: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    differingBillingRecipientId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    differingBillingRecipient: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    billingIsStopped: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    billingStoppedUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    billingStoppedReason: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    billingStoppedReminder: (0, _attr.default)('date', {
      defaultValue: null
    }),
    lastReceiptId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastReceiptNumber: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastReceiptDate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    openInvoiceId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    middlemanId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    middleman: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    tag1: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    tag2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    listText: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    notes: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastUpdate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    lastUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    parentServiceId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    parentIsRecipient: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    parentIsBillingAddressee: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    addressMiddleman: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    kindOfMiddleman: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    differingBillingAddresseeId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    differingBillingAddressee: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    paymentMethod: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    sepaMandateId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    differingBillingAddressIsTemp: (0, _attr.default)('boolean', {
      defaultValue: null
    })
  });

  _exports.default = _default;
});