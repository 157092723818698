define("alpha-data/models/postal-address", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    postalAddressId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    street: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    zipCode: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    city: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    federalState: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    poBox: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    poBoxZipCode: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    poBoxCity: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    country: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastUpdate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    lastUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    streetAffix: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    collectiveAddressFormat: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});