define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kGbaqwxN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[11,\"id\",[22,\"for\"]],[11,\"accept\",[22,\"accept\"]],[11,\"capture\",[22,\"capture\"]],[11,\"multiple\",[22,\"multiple\"]],[11,\"disabled\",[22,\"disabled\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"change\"],[[\"value\"],[\"target.files\"]]]],[10,\"hidden\",\"\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\\n\"],[14,1,[[24,[\"queue\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});