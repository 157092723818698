define("alpha-data/models/service-group", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    mandator: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    kindOfService: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    group: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    caption: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    price: (0, _attr.default)('number', {
      defaultValue: null
    }),
    taxRate: (0, _attr.default)('number', {
      defaultValue: null
    }),
    taxIsIncluded: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    receiptOrder: (0, _attr.default)('number', {
      defaultValue: null
    }),
    isFree: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    isSpecialCondition: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    positionCaption: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    differingReceiptCaption: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    receiptDescription: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    account: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    costObject: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    costUnit: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    order: (0, _attr.default)('number', {
      defaultValue: null
    }),
    articlePriceGroup: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    calculationDescription: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});