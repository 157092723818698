define("alpha-data/models/salutation-affix", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    salutationAffix: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    isSystem: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    bindToFormOfAddress: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    formOfAddress: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});