define("alpha-data/models/user", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    userUnits: (0, _relationships.hasMany)('user-unit'),
    userId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    salutation: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    title: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    firstName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    shortName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    eMailAddress: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    phoneMain: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    phoneExtension: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    faxMain: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    faxExtension: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    cellPhone: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    location: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    department: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    position: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastLogin: (0, _attr.default)('date', {
      defaultValue: ''
    }),
    validFrom: (0, _attr.default)('date', {
      defaultValue: ''
    }),
    expireDate: (0, _attr.default)('date', {
      defaultValue: ''
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: ''
    }),
    leftExtent: (0, _attr.default)('number', {
      defaultValue: null
    }),
    rightExtent: (0, _attr.default)('number', {
      defaultValue: null
    }),
    fullName: Ember.computed('salutation', 'firstName', 'lastName', function () {
      return [this.salutation, this.firstName, this.lastName].filter(function (val) {
        return val;
      }).join(' ');
    })
  });

  _exports.default = _default;
});