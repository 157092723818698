define("alpha-data/models/mail-account", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    mailAccountId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    name: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    protocol: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    senderEMailAddress: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    senderName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    host: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    port: (0, _attr.default)('number', {
      defaultValue: null
    }),
    userName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    password: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    sslMode: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    dedicatedUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastUpdate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    lastUser: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});