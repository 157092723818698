define('ember-intl/utils/macro', ['exports', 'ember-intl/utils/empty-object'], function (exports, _emptyObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createTranslatedComputedProperty;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var keys = Object.keys;

  function values(object) {
    return keys(object).map(function (key) {
      return object[key];
    });
  }

  function mapPropertiesByHash(object, hash) {
    var result = {};

    keys(hash).forEach(function (key) {
      result[key] = Ember.get(object, hash[key]);
    });

    return result;
  }

  function createTranslatedComputedProperty(key, options) {
    var _Ember;

    var hash = options || new _emptyObject.default();
    var dependentKeys = ['intl.locale'].concat(values(hash));

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(dependentKeys).concat([function () {
      var intl = Ember.get(this, 'intl');
      (false && !(intl) && Ember.assert('Cannot translate "' + key + '".\n' + this + ' does not have an intl property set. Try: intl: Ember.inject.service()', intl));


      return intl.t(key, mapPropertiesByHash(this, hash));
    }])).readOnly();
  }
});