define("alpha-data/models/service-position", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    servicePositionId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    serviceId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    caption: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    beginning: (0, _attr.default)('date', {
      defaultValue: null
    }),
    end: (0, _attr.default)('date', {
      defaultValue: null
    }),
    group: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    groupCaption: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    price: (0, _attr.default)('number', {
      defaultValue: null
    }),
    pricingInfo: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    quantity: (0, _attr.default)('number', {
      defaultValue: null
    }),
    discountPercentage: (0, _attr.default)('number', {
      defaultValue: null
    }),
    discountAmount: (0, _attr.default)('number', {
      defaultValue: null
    }),
    totalPrice: (0, _attr.default)('number', {
      defaultValue: null
    }),
    receiptPattern: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    receiptTotalPrice: (0, _attr.default)('number', {
      defaultValue: null
    }),
    taxRate: (0, _attr.default)('number', {
      defaultValue: null
    }),
    taxIsIncluded: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    nextReceiptDate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    currency: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    paymentPattern: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    paymentRate: (0, _attr.default)('number', {
      defaultValue: null
    }),
    firstPaymentRate: (0, _attr.default)('number', {
      defaultValue: null
    }),
    lastPaymentRate: (0, _attr.default)('number', {
      defaultValue: null
    }),
    receiptCaption: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    receiptDescription: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    receiptOrder: (0, _attr.default)('number', {
      defaultValue: null
    }),
    lastReceiptId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastReceiptNumber: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    differingServiceRecipientId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    differingServiceRecipient: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    serviceIsBlocked: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    account: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    costObject: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    costUnit: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    shouldNotBePrinted: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    shouldNotBeAccounted: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    lastBillingTransactionId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastNextReceiptDate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastUpdate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    lastUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    donation: (0, _attr.default)('number', {
      defaultValue: null
    }),
    articlePriceGroup: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    articleId: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});