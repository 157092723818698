define('ember-intl/utils/missing-message', ['exports', 'ember-intl/utils/links'], function (exports, _links) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = missingMessage;
  function missingMessage(key, locales) {
    if (Ember.isEmpty(locales)) {
      (false && Ember.warn('[ember-intl] no locale has been set. Documentation: ' + _links.default.unsetLocale, false, {
        id: 'ember-intl-no-locale-set'
      }));


      return 'No locale defined.  Unable to resolve translation: "' + key + '"';
    }

    var localeNames = locales.join(', ');

    (false && Ember.warn('[ember-intl] translation: "' + key + '" on locale: "' + localeNames + '" was not found.', false, {
      id: 'ember-intl-missing-translation'
    }));


    return 'Missing translation "' + key + '" for locale "' + localeNames + '"';
  }
});