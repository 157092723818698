define('ember-custom-actions/actions/model', ['exports', 'ember-custom-actions/actions/action'], function (exports, _action) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (path) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return function () {
      var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var customOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return _action.default.create({
        model: this,
        instance: true,
        options: assign({}, options, customOptions),
        payload: payload,
        path: path
      }).callAction();
    };
  };

  var assign = Ember.assign;
});