define('ember-file-upload/system/parse-json', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = parseJSON;
  function parseJSON(string) {
    var json = null;
    try {
      json = JSON.parse(string);
    } catch (e) {
      (false && !(false) && Ember.assert('Invalid JSON: ' + string));
    }
    return json;
  }
});