define("alpha-data/models/process-log-submessage", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    processLogEntry: (0, _relationships.belongsTo)('process-log', {
      async: false
    }),
    submessageId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    processLogEntryId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    time: (0, _attr.default)('date', {
      defaultValue: null
    }),
    messageType: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    subject: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    messageText: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    moduleItemId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    sourceReference: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    resultReference: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    resultId: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});