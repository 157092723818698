define('ember-inputmask/components/one-way-ssn-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _oneWayInputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oneWayInputMask.default.extend({
    /**
     * @field mask
     * @override
     */
    mask: '999-99-9999'
  });
});