define("alpha-data/models/company", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    companyId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    company1: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    company2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    company3: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    company4: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    company1Phonetic: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    abbreviation: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    dateOfFormation: (0, _attr.default)('date', {
      defaultValue: null
    }),
    vATId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    vendorNumber: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    profile: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    restrictionNote: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    label: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    notes: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    imageBlobId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    }),
    createUser: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastUpdate: (0, _attr.default)('date', {
      defaultValue: null
    }),
    lastUser: (0, _attr.default)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});