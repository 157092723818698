define('ember-component-css/mixins/style-namespacing-extras', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    _componentIdentifier: computed({
      get: function get() {
        return (this._debugContainerKey || '').replace('component:', '');
      }
    }),

    _shouldAddNamespacedClassName: computed({
      get: function get() {
        return this.get('tagName') !== '' && this.get('componentCssClassName');
      }
    })
  });
});