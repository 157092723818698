define("ember-leaflet/helpers/lat-lng-bounds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.latLngBounds = _exports.default = void 0;
  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  var latLngBounds = isFastBoot ? function () {} : function (latLngs) {
    return L.latLngBounds(latLngs);
  };
  _exports.latLngBounds = latLngBounds;

  var _default = Ember.Helper.helper(latLngBounds);

  _exports.default = _default;
});