define("ember-light-table/templates/components/lt-scaffolding-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fs/4rnsK",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[11,\"style\",[28,\"html-safe\",[[28,\"if\",[[23,1,[\"width\"]],[28,\"concat\",[\"width: \",[23,1,[\"width\"]]],null]],null]],null]],[10,\"class\",\"lt-scaffolding\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-scaffolding-row.hbs"
    }
  });

  _exports.default = _default;
});