define('ember-custom-actions/index', ['exports', 'ember-custom-actions/actions/resource', 'ember-custom-actions/actions/model', 'ember-custom-actions/serializers/json-api', 'ember-custom-actions/serializers/rest'], function (exports, _resource, _model, _jsonApi, _rest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RESTSerializer = exports.JSONAPISerializer = exports.resourceAction = exports.modelAction = undefined;
  exports.modelAction = _model.default;
  exports.resourceAction = _resource.default;
  exports.JSONAPISerializer = _jsonApi.default;
  exports.RESTSerializer = _rest.default;
});