define('ember-inputmask/components/one-way-date-mask', ['exports', 'ember-inputmask/components/one-way-input-mask'], function (exports, _oneWayInputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_OPTIONS = {
    inputFormat: 'dd/mm/yyyy',
    outputFormat: 'ddmmyyyy'
  };

  /**
   * @class OneWayDateMask
   */
  exports.default = _oneWayInputMask.default.extend({
    /**
     * @field mask
     * @override
     */
    mask: 'datetime',

    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, '_options', Ember.assign({}, Ember.get(this, '_options'), DEFAULT_OPTIONS));
    }
  });
});