define("ember-light-table/templates/components/cells/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q9KCmWdb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"column\",\"cellComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[24,[\"column\",\"cellComponent\"]]],[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[24,[\"tableActions\"]],[24,[\"extra\"]],[24,[\"table\"]],[24,[\"column\"]],[24,[\"row\"]],[24,[\"value\"]],[24,[\"rawValue\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/cells/base.hbs"
    }
  });

  _exports.default = _default;
});