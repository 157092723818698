define('ember-intl/formatters/format-message', ['exports', 'intl-format-cache', 'intl-messageformat', 'ember-intl/formatters/-base'], function (exports, _intlFormatCache, _intlMessageformat, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FormatMessage = _base.default.extend({
    formatter: Ember.computed({
      get: function get() {
        return (0, _intlFormatCache.default)(_intlMessageformat.default);
      }
    }).readOnly(),

    format: function format(value, options, _ref) {
      var formats = _ref.formats,
          locale = _ref.locale;

      return Ember.get(this, 'formatter')(value, locale, formats).format(options);
    }
  });

  FormatMessage.reopenClass({
    formatType: 'message'
  });

  exports.default = FormatMessage;
});