define("alpha-components/templates/components/alpha-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B0XF8onW",
    "block": "{\"symbols\":[\"p\"],\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"not-eq\",[[24,[\"totalPages\"]],1],null],[24,[\"meta\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui pagination menu\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showFirst\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",true],[10,\"class\",\"item\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"change\",1],null]],[10,\"role\",\"button\"],[8],[0,\"1\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showFirstDivider\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",true],[10,\"class\",\"disabled item\"],[8],[0,\"...\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"pages\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",true],[11,\"class\",[29,[[28,\"if\",[[28,\"eq\",[[24,[\"page\"]],[23,1,[]]],null],\"active\"],null],\" item\"]]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"change\",[23,1,[]]],null]],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showLast\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"showLastDivider\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\",true],[10,\"class\",\"disabled item\"],[8],[0,\"...\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"a\",true],[10,\"class\",\"item\"],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"change\",[24,[\"totalPages\"]]],null]],[8],[1,[22,\"totalPages\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "alpha-components/templates/components/alpha-pagination.hbs"
    }
  });

  _exports.default = _default;
});