define("alpha-data/models/event-participant", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    eventRootItem: (0, _relationships.belongsTo)('event-item', {
      inverse: 'eventParticipants'
    }),
    contact: (0, _relationships.belongsTo)('contact'),
    temporaryContact: (0, _relationships.belongsTo)('temporary-contact'),
    //***
    //*** GENERATED
    //***
    eventParticipantId: (0, _attr.default)('string'),
    eventRootItemId: (0, _attr.default)('string'),
    eventSerialNumber: (0, _attr.default)('string'),
    eventTitle: (0, _attr.default)('string'),
    contactId: (0, _attr.default)('string'),
    participantName: (0, _attr.default)('string'),
    participantNumber: (0, _attr.default)('number'),
    billingContactId: (0, _attr.default)('string'),
    billingContactName: (0, _attr.default)('string'),
    role: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    priceGroup: (0, _attr.default)('string'),
    isExpense: (0, _attr.default)('boolean'),
    registrationDate: (0, _attr.default)('date'),
    reservationDeadline: (0, _attr.default)('date'),
    totalPrice: (0, _attr.default)('number'),
    currency: (0, _attr.default)('string'),
    allowCollectiveBilling: (0, _attr.default)('boolean'),
    billingInformation: (0, _attr.default)('string'),
    openInvoiceId: (0, _attr.default)('string'),
    cancellationDate: (0, _attr.default)('date'),
    label1: (0, _attr.default)('string'),
    label2: (0, _attr.default)('string'),
    label3: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string'),
    dateOfOrder: (0, _attr.default)('date'),
    customerReferenceNumber: (0, _attr.default)('string'),
    eMailAddress: (0, _attr.default)('string'),
    appearance: (0, _attr.default)('string'),
    contactIsTemporary: (0, _attr.default)('boolean'),
    temporaryContactId: (0, _attr.default)('string'),
    confirmationText: (0, _attr.default)('string'),
    individualReportText: (0, _attr.default)('string'),
    termOfPayment: (0, _attr.default)('string'),
    paymentStart: (0, _attr.default)('date'),
    paymentPattern: (0, _attr.default)('string'),
    paymentRate: (0, _attr.default)('number'),
    firstPaymentRate: (0, _attr.default)('number'),
    lastPaymentRate: (0, _attr.default)('number'),
    paymentMethod: (0, _attr.default)('string'),
    processNumber: (0, _attr.default)('number'),
    parentId: (0, _attr.default)('string'),
    parentName: (0, _attr.default)('string'),
    parentRelation: (0, _attr.default)('string'),
    parentRelationDescription: (0, _attr.default)('string'),
    parentInverseDescription: (0, _attr.default)('string'),
    billingIsStopped: (0, _attr.default)('boolean'),
    billingStoppedUser: (0, _attr.default)('string'),
    billingStoppedReason: (0, _attr.default)('string'),
    billingStoppedReminder: (0, _attr.default)('date'),
    lastReceiptId: (0, _attr.default)('string'),
    lastReceiptNumber: (0, _attr.default)('string'),
    lastReceiptDate: (0, _attr.default)('date'),
    text1: (0, _attr.default)('string'),
    text2: (0, _attr.default)('string'),
    text3: (0, _attr.default)('string'),
    date1: (0, _attr.default)('date'),
    date1IsReminder: (0, _attr.default)('boolean'),
    date1User: (0, _attr.default)('string'),
    date2: (0, _attr.default)('date'),
    date2IsReminder: (0, _attr.default)('boolean'),
    date2User: (0, _attr.default)('string'),
    integer1: (0, _attr.default)('number'),
    integer2: (0, _attr.default)('number'),
    numeric1: (0, _attr.default)('number'),
    bool1: (0, _attr.default)('boolean'),
    bool2: (0, _attr.default)('boolean'),
    created: (0, _attr.default)('date'),
    createUser: (0, _attr.default)('string'),
    lastUpdate: (0, _attr.default)('date'),
    lastUser: (0, _attr.default)('string'),
    companyId: (0, _attr.default)('string'),
    companyName: (0, _attr.default)('string')
  });

  _exports.default = _default;
});