define("alpha-data/models/event-item-price", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    eventItem: (0, _relationships.belongsTo)('event-item', {
      async: false
    }),
    groupName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    price: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    created: (0, _attr.default)('date', {
      defaultValue: null
    })
  });

  _exports.default = _default;
});