define("ember-simple-auth-auth0/authenticators/auth0-base", ["exports", "ember-simple-auth/authenticators/base", "ember-simple-auth-auth0/utils/errors", "ember-simple-auth-auth0/utils/create-session-data-object", "ember-simple-auth-auth0/utils/get-session-expiration", "ember-simple-auth-auth0/utils/now"], function (_exports, _base, _errors, _createSessionDataObject, _getSessionExpiration, _now) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    auth0: Ember.inject.service(),
    restore: function restore(data) {
      var expiresAt = (0, _getSessionExpiration.default)(data || {});

      if (expiresAt > (0, _now.default)()) {
        return Ember.RSVP.resolve(data);
      } else if (Ember.get(this, 'auth0.silentAuthOnSessionRestore')) {
        return this._performSilentAuth();
      } else {
        return Ember.RSVP.reject();
      }
    },
    // resolve the data returned by a parseHash/silentAuth result.
    _resolveAuthResult: function _resolveAuthResult(authResult, resolve, reject) {
      if (Ember.isEmpty(authResult)) {
        reject();
      }

      var auth0 = Ember.get(this, 'auth0')._getAuth0Instance();

      var getUserInfo = auth0.client.userInfo.bind(auth0.client);
      getUserInfo(authResult.accessToken, function (err, profile) {
        if (err) {
          return reject(new _errors.Auth0Error(err));
        }

        resolve((0, _createSessionDataObject.default)(profile, authResult));
      });
    },
    // performs silent authentication & handles the result in a promise.
    _performSilentAuth: function _performSilentAuth(options) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          // perform silent auth via auth0's checkSession function (called in the service);
          // if successful, use the same logic as the url-hash authenticator since the
          // result of checkSession is the same as parseHash.
          Ember.get(_this, 'auth0').silentAuth(options).then(function (authenticatedData) {
            _this._resolveAuthResult(authenticatedData, resolve, reject);
          }, function (error) {
            // for any error types other than login_required, log it to the console.
            // otherwise, there are a few cases (auto-renewal, basically) where the
            // error details will get swallowed completely. Better to give feedback.
            if (console && error && error.name && error.name !== 'login_required') {
              console.warn("Silent authentication failed: ".concat(error.message)); // eslint-disable-line no-console
            }

            reject(error);
          });
        } catch (e) {
          // Explictly catch, log, and rethrow errors because promises hide them.
          // This lets deeper Ember.assert calls actually log errors in development.
          console.log(e); // eslint-disable-line no-console

          throw e;
        }
      });
    }
  });

  _exports.default = _default;
});