define('ember-custom-actions/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    type: 'PUT',
    ajaxOptions: {},
    pushToStore: false,
    normalizeOperation: '',
    promiseType: null
  };
});