define("alpha-data/models/temporary-contact", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    //***
    //*** GENERATED
    //***
    temporaryContactId: (0, _attr.default)('string'),
    salutation: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    dateOfBirth: (0, _attr.default)('date'),
    company1: (0, _attr.default)('string'),
    company2: (0, _attr.default)('string'),
    company3: (0, _attr.default)('string'),
    company4: (0, _attr.default)('string'),
    role: (0, _attr.default)('string'),
    department: (0, _attr.default)('string'),
    street: (0, _attr.default)('string'),
    zipCode: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    poBox: (0, _attr.default)('string'),
    poBoxZipCode: (0, _attr.default)('string'),
    poBoxCity: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    fax: (0, _attr.default)('string'),
    eMailAddress: (0, _attr.default)('string'),
    homepage: (0, _attr.default)('string'),
    miscContact: (0, _attr.default)('string'),
    formOfAddress: (0, _attr.default)('string'),
    category: (0, _attr.default)('string'),
    tag: (0, _attr.default)('string'),
    module: (0, _attr.default)('string'),
    itemId: (0, _attr.default)('string'),
    created: (0, _attr.default)('date'),
    createUser: (0, _attr.default)('string'),
    lastUpdate: (0, _attr.default)('date'),
    lastUser: (0, _attr.default)('string')
  });

  _exports.default = _default;
});