define("ember-light-table/templates/components/columns/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NDsIIwwT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"column\",\"component\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[24,[\"column\",\"component\"]]],[[\"column\",\"table\",\"tableActions\",\"extra\",\"sortIcons\"],[[24,[\"column\"]],[24,[\"table\"]],[24,[\"tableActions\"]],[24,[\"extra\"]],[24,[\"sortIcons\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"sortIcons\",\"iconComponent\"]],[24,[\"sortIconProperty\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"sortIcons\",\"iconComponent\"]]],[[\"sortIcons\",\"sortIconProperty\"],[[24,[\"sortIcons\"]],[24,[\"sortIconProperty\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"sortIconProperty\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"lt-sort-icon \",[28,\"get\",[[24,[\"sortIcons\"]],[24,[\"sortIconProperty\"]]],null]]]],[8],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"isResizable\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"lt-column-resizer\",null,[[\"column\",\"table\",\"resizeOnDrag\",\"isResizing\",\"onColumnResized\"],[[24,[\"column\"]],[24,[\"table\"]],[24,[\"resizeOnDrag\"]],[28,\"mut\",[[24,[\"isResizing\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"onColumnResized\"]],[24,[\"column\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/columns/base.hbs"
    }
  });

  _exports.default = _default;
});