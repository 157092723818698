define('ember-custom-actions/actions/action', ['exports', 'ember-custom-actions/utils/url-builder', 'ember-custom-actions/utils/normalize-payload', 'ember-custom-actions/config'], function (exports, _urlBuilder, _normalizePayload, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assign = Ember.assign,
      getOwner = Ember.getOwner,
      computed = Ember.computed,
      EmberObject = Ember.Object,
      ObjectProxy = Ember.ObjectProxy,
      ArrayProxy = Ember.ArrayProxy,
      PromiseProxyMixin = Ember.PromiseProxyMixin,
      emberTypeOf = Ember.typeOf,
      isArray = Ember.isArray,
      RSVP = Ember.RSVP;


  var promiseTypes = {
    array: ArrayProxy.extend(PromiseProxyMixin),
    object: ObjectProxy.extend(PromiseProxyMixin)
  };

  exports.default = EmberObject.extend({
    model: null,
    options: {},
    payload: {},
    instance: false,

    store: computed.reads('model.store'),

    params: computed('config.params', 'adapter', function () {
      var params = emberTypeOf(this.get('config.params')) === 'object' ? this.get('config.params') : {};
      return this.get('adapter').sortQueryParams(params);
    }),

    modelName: computed('model', function () {
      var _get = this.get('model'),
          constructor = _get.constructor;

      return constructor.modelName || constructor.typeKey;
    }),

    adapter: computed('modelName', 'store', function () {
      return this.get('store').adapterFor(this.get('modelName'));
    }),

    serializer: computed('modelName', 'store', function () {
      return this.get('store').serializerFor(this.get('modelName'));
    }),

    appConfig: computed('model', function () {
      var config = getOwner(this.get('model')).resolveRegistration('config:environment').emberCustomActions || {};
      return EmberObject.create(config);
    }),

    defaultConfig: computed(function () {
      return EmberObject.create(_config.default);
    }),

    config: computed('defaultConfig', 'options', 'appConfig', function () {
      return EmberObject.create(assign({}, this.get('defaultConfig'), this.get('appConfig'), this.get('options')));
    }),

    requestType: computed('config.type', function () {
      return this.get('config.type').toUpperCase();
    }),

    urlType: computed.or('config.urlType', 'requestType'),

    url: computed('model', 'path', 'urlType', 'instance', 'adapter', function () {
      return _urlBuilder.default.create({
        path: this.get('path'),
        adapter: this.get('adapter'),
        urlType: this.get('urlType'),
        instance: this.get('instance'),
        model: this.get('model'),
        params: this.get('params')
      }).build();
    }),

    data: computed('config.{normalizeOperation,ajaxOptions}', 'payload', function () {
      var payload = emberTypeOf(this.get('payload')) === 'object' ? this.get('payload') : {};
      var data = (0, _normalizePayload.default)(payload, this.get('config.normalizeOperation'));

      return assign({}, this.get('config.ajaxOptions'), { data: data });
    }),

    promiseType: computed('config.promiseType', function () {
      return promiseTypes[this.get('config.promiseType')];
    }),

    callAction: function callAction() {
      var promise = this._promise();
      return this.get('promiseType') ? this.get('promiseType').create({ promise: promise }) : promise;
    },
    _promise: function _promise() {
      return this.get('adapter').ajax(this.get('url'), this.get('requestType'), this.get('data')).then(this._onSuccess.bind(this), this._onError.bind(this));
    },
    _onSuccess: function _onSuccess(response) {
      if (this.get('config.pushToStore') && this._validResponse(response)) {
        return this.get('serializer').pushPayload(this.get('store'), response);
      }

      return response;
    },
    _onError: function _onError(error) {
      if (this.get('config.pushToStore') && isArray(error.errors)) {
        var id = this.get('model.id');
        var typeClass = this.get('model').constructor;
        error.serializedErrors = this.get('serializer').extractErrors(this.get('store'), typeClass, error, id);
      }

      return RSVP.reject(error);
    },
    _validResponse: function _validResponse(object) {
      return emberTypeOf(object) === 'object' && Object.keys(object).length > 0;
    }
  });
});