define("ember-leaflet/macros/to-lat-lng", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _default() {
    var latKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'lat';
    var lngKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'lng';
    return Ember.computed(latKey, lngKey, {
      get: function get() {
        var _ref = [this.get(latKey), this.get(lngKey)],
            lat = _ref[0],
            lng = _ref[1];
        return this.L.latLng(lat, lng);
      },
      set: function set(key, value) {
        var _this$setProperties;

        this.setProperties((_this$setProperties = {}, _defineProperty(_this$setProperties, latKey, value ? value.lat : value), _defineProperty(_this$setProperties, lngKey, value ? value.lng : value), _this$setProperties));
        return value;
      }
    });
  }
});